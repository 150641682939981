import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate , Link, json} from 'react-router-dom';
import BASE_URL from "../../Api/base";
import logo from '../../assets/images/logo2-1.png'
import {toast} from 'react-toastify'

const Login = ({admin}) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();
  const loggedInAdmin = JSON.parse(localStorage.getItem("adminData"));

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  useEffect(()=> {
    if(loggedInAdmin){
        navigate('/v2/dashboard');
    }
  },[])

  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .post(BASE_URL + "admin/login", {
        email: formData.email,
        password: formData.password,
      })
      .then((response) => {
          const responseData = response.data;
          if (responseData.responseCode == '200') {
            const adminData = JSON.stringify(responseData.responsResult);
            localStorage.setItem("adminData", adminData);
            admin(responseData.responsResult) 
            navigate('/v2/dashboard')
          } else {
            toast.error(responseData.responseMessage) 
            console.log(responseData.responseMessage);
          }
      })
      .catch((error) => {
        console.log("An error occurred. Please try again.");
      });
  };


  return (
    <div className="main-content">
      <div className="login-wraper">
      <div className="signup-container1">
        <div className="text-center">
       <img src={logo} alt="logoImg"/>
       </div>
        {/* <h5 className="text-center mb-4"> Admin Panel</h5> */}
        <form onSubmit={handleLogin} className="mb-3">
          <div className="form-group">
          <input className="form-control"
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
          </div>
          <div className="form-group">
          <input className="form-control"
            type="password"
            placeholder="Password"
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
          />
          </div>
          {errorMessage && <p className="errorMsg" style={{color: "red"}}>{errorMessage}</p>}

          <button  className="btn btn-primary" type="submit" style={{width:'100%' , marginLeft:'0%', backgroundColor:'#0d6efd !importent'}}>Login</button>
        </form>
        <div className="forgetPass d-flex justify-content-between align-items-center">
          <p><Link to="/v2/forgotpassword"><i className="fa-solid fa-lock"></i>Forgot Password</Link></p>
          <p><Link target='_blank' to="https://partners.adomobi.com/partner/signup">Create an account</Link></p>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from 'react'
import {Outlet, Navigate, useNavigate} from 'react-router-dom'

const PrivateRoute = ({admin}) => {
  const navigate = useNavigate()
  const loggedIn = JSON.parse(localStorage.getItem('adminData'))
  useEffect(()=> {
    if (!loggedIn) {
      navigate('/v2/login')
      admin(null)
    }else {
        admin(loggedIn)
    }
  },[])
  return loggedIn ? <Outlet /> : <Navigate to={"/v2/login"} />
  
}

export default PrivateRoute
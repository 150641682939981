import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BASE_URL from "../../Api/base";

const Users = ()=> {

  const loggedUser = JSON.parse(localStorage.getItem('adminData'));
  const [clients, setClients] = useState([])
  const [status, setStatus] = useState({})

    const fetchUsers = async()=>{
        const response = await fetch(BASE_URL+`contactUs/messageList?adminId=${loggedUser._id}`)
        const resData = await response.json()
        setClients(resData.responseResult)
    }


    useEffect(()=>{
      fetchUsers()
    },[])
    return (
        <>
         <div className="'Container-fluid">
                    <div className='page_sec p-0'>
                        <div className="container mt-2">
                        <div className='container_table m-0'>
            <div className="table-container">
              <div className="row">
                <div className="col-lg-12 d-flex justify-content-between align-items-center mb-3">
                  <input className="form-control"
                    type="text"
                    placeholder="Search..."
                  />
                </div>
              
              </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Password</th>
                    <th>Domain</th>
                    <th>Plan</th>
                    <th>Mobile Number</th>
                    <th>Skype ID</th>
                    <th>Company</th>
                    <th>Country</th>
                  </tr>
                </thead>
                <tbody>
                  {clients
                    ?.map((click) => (
                      <tr key={click.Id}>
                        <td>{click.Id}</td>
                        <td><Link to={`../user/details/${click.Id}`}>{click.name}</Link></td>
                        <td>{click.email}</td>
                        <td>{click.password}</td>
                        <td>{click?.domain}</td>
                        <td>(ID: {click?.planId}) {click?.planName}</td>
                        <td>{click.mobileNumber}</td>
                        <td>{click.skypeId}</td>
                        <td>{click.company}</td>
                        <td>{click.country}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              </div>
            </div>
          </div>
            </div>
            </div>
            </div>
        </>
    )
}

export default Users
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BASE_URL from "../../../Api/base";

const UserDetails = ()=> {
    const [clientDetails, setClientDetails] = useState({})
    const {id} = useParams();
    const loggedUser = JSON.parse(localStorage.getItem('adminData'))

    const fetchUsertDetails = async()=> {
        const res = await fetch(BASE_URL+`contactUs/viewMessage?adminId=${loggedUser._id}&Id=${id}`)
        const resData = await res.json()
        setClientDetails(resData.responseResult)
    }
    useEffect(()=>{
        fetchUsertDetails()
    },[])

    return (
        <div className="'Container-fluid">
            <div className='page_sec p-0'>
                <div className="container mt-2">
                    <div className='row'>
                        <div className="col-lg-6">
                            <div className="offersData">
                                <h4>User Details</h4>
                                <hr />
                                <div className="offersDataItem">
                                    <p>Id:</p>
                                    <span>{clientDetails.Id}</span> 
                                </div>
                                <div className="offersDataItem">
                                    <p>Name:</p>
                                    <span>{clientDetails.name}</span> 
                                </div>
                                <div className="offersDataItem">
                                    <p>Email:</p>
                                    <span>{clientDetails.email}</span> 
                                </div>
                                <div className="offersDataItem">
                                    <p>Company:</p>
                                    <span>{clientDetails.company}</span> 
                                </div>
                                <div className="offersDataItem">
                                    <p>Plan Id:</p>
                                    <span>{clientDetails.planId}</span> 
                                </div>
                            </div>
                                

                        </div>

                    </div>
            </div>
            </div>
        </div>
    )
}

export default UserDetails;
import React, { useRef, useState } from "react";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from "react-router-dom";
import profile from '../../assets/images/avtar.png'

const Topbar = ({admin})=> {
    const [isLogout, setIsLogout] = useState(false)
    const dropdownRef = useRef(null);
    const navigate = useNavigate()

    const openDropdown = ()=> {
        setIsLogout(!isLogout)
      }
    const logoutUser = ()=> {
        localStorage.removeItem("adminData");
        navigate("/v2/login")
        admin(null)
      }
    return (
        <>
    <Navbar bg="light" data-bs-theme="light">
        <Container>
          <div className='right-nav d-flex justify-content-end w-100'>
              <div className="profile" onClick={openDropdown}>
                <img src={profile} alt="Profile" className="profile-image" width={40}/>
              </div>
              {isLogout && 
              <div className='profile_option' ref={dropdownRef}>
                <button onClick={logoutUser}>Logout</button>
              </div>
              }
            </div>
            </Container>
      </Navbar>
        </>
    )
}
export default Topbar;
import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";

function RedirectRoute() {
    const loggedIn = JSON.parse(localStorage.getItem('adminData'))
    const navigate = useNavigate()
    useEffect(()=> {
        if(loggedIn){
            navigate('/v2/dashboard')
        }else{
            navigate('/v2/login')
        }

    })
    
  return (
    <></>
  )
}

export default RedirectRoute
import React, { useEffect } from "react";
import Cards from "../../components/Cards";

const Dashboard = ({admin})=> {
    const loggedIn = JSON.parse(localStorage.getItem('adminData'))
    useEffect(()=> {
        admin(loggedIn)
    },[])

    return (
        <>
            <div className="'Container-fluid">
                <div className='page_sec'>
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-lg-4">
                            <Cards title="Active Clients" />
                            </div>
                            <div className="col-lg-4">
                            <Cards title="Pending Clients"/>
                            </div>
                            <div className="col-lg-4">
                            {/* <AdminCard title="Pending Clients"/> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard;
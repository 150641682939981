import React, { useEffect, useState } from 'react';
import './App.css';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import store from './Redux/store'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'react-bootstrap';
import Topbar from './components/global/topbar';
import Sidebar from './components/global/sidebar';
import Dashboard from './pages/dashboard';
import Clients from './pages/Clients';
import ClientsDetails from './pages/Clients/ClientsDetails';
import Login from './pages/Login';
import ForgotPassword from './pages/Login/ForgotPassword';
import ResetPassword from './pages/Login/ResetPassword';
import VerifyOtp from './pages/Login/VerifyOtp';
import RedirectRoute from './pages/RedirectRoute';
import Users from './pages/Users';
import UserDetails from './pages/Users/UserDetails';
import PrivateRoute from './components/PrivateRoute.jsx';

const App = () => {
  const [admin, setAdmin] = useState(null)
  const loggedIn = JSON.parse(localStorage.getItem('adminData'))

  return (
    <div className='App main d-flex'>
    <ToastContainer />
    <Provider store={store}>
      <BrowserRouter>
     {admin && <Sidebar />}
      <div className="right-content">
     {admin && <Topbar admin={setAdmin} />}
      <div className="main-content">
          <Routes>
            <Route path='/' element={<RedirectRoute />}></Route>
            <Route path='/v2/login' element={<Login admin={setAdmin}/>}></Route>
            <Route path="/v2/forgotpassword" element={<ForgotPassword />}></Route>
            <Route path="/v2/varifyotp" element={<VerifyOtp />}></Route>
            <Route path='/v2/resetpassword' element={<ResetPassword />}></Route>
            <Route path='/v2' element={<PrivateRoute admin={setAdmin} />}>
              <Route path='dashboard' element={<Dashboard admin={setAdmin} />}></Route>
              <Route path='clients' element={<Clients />} />
              <Route path='client/details/:id' element={<ClientsDetails />} />
              <Route path='users' element={<Users />} />
              <Route path='user/details/:id' element={<UserDetails />} />
            </Route>
          </Routes>
          </div>
          </div>
      </BrowserRouter>
    </Provider>
    </div>
  );
};

export default App;